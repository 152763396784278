import { Tooltip } from '@mui/material';
import { limitedArrayField } from '../../helpers';

export const ArrayWithTooltip = ({ source, record, maxDisplay = 3 }) => {
  const fieldData = record?.[source] || [];

  return (
    <Tooltip
      title={fieldData.map((item) => <div key={item}>{item}</div>)}
      placement="top"
      arrow
    >
      {limitedArrayField(fieldData, maxDisplay)}
    </Tooltip>
  );
};
