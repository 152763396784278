import { getBrandsByStatus } from '../../../../../utils/meta/mediaPlatformApiSettingsUtils';

export const TrackCBHelperText = ({ brandFetchStatus }) => {
  const defaultHelperText = "When creating Pixel settings for the first time, Select Last 7 Days or Current. Once initial data is captured, either for Current or Last 7 days, this setting will change to Last Record Time. Last Record Time (within 7 days) will ensure all records are captured during any occurrence of downtime or delay.";

  const successfulBrands = getBrandsByStatus(brandFetchStatus, true);
  const failedBrands = getBrandsByStatus(brandFetchStatus, false);

  return (
    <>
      <span>{defaultHelperText}</span>
      <div style={{ marginTop: '10px', color: failedBrands.length > 0 ? 'red' : '#00000099' }}>
        {failedBrands.length > 0 && <>Failed: {failedBrands.join(', ')}. </>}
        {successfulBrands.length > 0 && <>Successful Brands Update: {successfulBrands.join(', ')}.</>}
      </div>
    </>
  );
};
